import $ from "jquery";

import Scrollbar from "smooth-scrollbar";
import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

jQuery(document).ready(function ($) {
// window.addEventListener("DOMContentLoaded", (e) => {
	console.log("DOMContentLoaded");
	console.log("Loaded");

	$(".menu-icon").click(function () {
		$("body").toggleClass("menu-open");
		$(".menu-overlay").toggleClass("is-active");
	});

	$(".menu-overlay").click(function () {
		$("body").removeClass("menu-open");
		$(".menu-overlay").removeClass("is-active");
	});

	var header = $("#myHeader");

	if (!$(header).length) {
		return;
	}

	// $(window).on("scroll", function () {
	// 	if ($(window).scrollTop() > 50) {
	// 		header.addClass("sticky");
	// 	} else {
	// 		header.removeClass("sticky");
	// 	}
	// });

	gsap.registerPlugin(ScrollTrigger);

	let bodyScrollBar;
	let scroller;
	const select = (e) => document.querySelector(e);
	const body = document.querySelector("body");

	// run only if element present
	const imgtest = document.querySelector(".intro_img01");
	if (imgtest) {
		if (window.innerWidth <= 760) {
			let $h2Mm = gsap.timeline();
			$h2Mm
				.to(".mn_screen .intro_hold .intro_img07", 1.8, {
					scale: 1,
					clipPath: "inset(0%)",
					ease: "expo.out",
				})
				.add("a")
				.to(
					".mn_screen .intro_hold .intro_img02",
					1.8,
					{
						scale: 1,
						clipPath: "inset(0%)",
						ease: "expo.out",
					},
					`-=${0.85 * 1.8}`
				) //.to('.mn_screen .intro_hold .intro_img05', 1.8, { scale: 1, clipPath: 'inset(0%)', ease: "expo.out" }, `-=${0.85 * 1.8}`)
				.to(
					".mn_screen .intro_hold .intro_img04",
					1.8,
					{
						scale: 1,
						clipPath: "inset(0%)",
						ease: "expo.out",
					},
					`-=${0.85 * 1.8}`
				)
				.to(
					".mn_screen .intro_hold .intro_img03",
					1.8,
					{
						scale: 1,
						clipPath: "inset(0%)",
						ease: "expo.out",
					},
					`-=${0.85 * 1.8}`
				)
				.to(
					".mn_screen .intro_hold .intro_img01",
					1.8,
					{
						scale: 1,
						clipPath: "inset(0%)",
						ease: "expo.out",
					},
					`-=${0.85 * 1.8}`
				)
				.to(
					".mn_screen .intro_hold .intro_img06",
					1.8,
					{
						scale: 1,
						clipPath: "inset(0%)",
						ease: "expo.out",
					},
					`-=${0.85 * 1.8}`
				)
				.to(
					".intro_hold_h1 .h2g",
					1.8,
					{
						y: "-50%",
						ease: "expo.out",
					},
					`-=1.9`
				)
				.to(
					".ms_txt",
					1.5,
					{
						autoAlpha: 1,
						y: 0,
						ease: "expo.out",
					},
					"a+=1"
				)
				.to(
					".mn_header",
					1,
					{
						autoAlpha: 1,
						pointerEvents: "auto",
						y: 0,
						ease: "expo.out",
						immediateRender: false,
					},
					"a+=1"
				)
				.to(
					".h_burger_mb",
					1,
					{
						autoAlpha: 1,
						pointerEvents: "auto",
						y: 0,
						ease: "expo.out",
						immediateRender: false,
					},
					"a+=1"
				)
				.to(".hdn", 0.01, {
					height: "auto",
					onComplete: initPageMb,
				});
		} else if (window.innerWidth > 760) {
			let $h2M = gsap.timeline();
			$h2M.to(".mn_screen .intro_hold .intro_img01", 1.8, {
				scale: 1,
				clipPath: "inset(0%)",
				ease: "expo.out",
			})
				.add("a")
				.to(
					".mn_screen .intro_hold .intro_img02",
					1.8,
					{
						scale: 1,
						clipPath: "inset(0%)",
						ease: "expo.out",
					},
					`-=${0.85 * 1.8}`
				)
				.to(
					".mn_screen .intro_hold .intro_img05",
					1.8,
					{
						scale: 1,
						clipPath: "inset(0%)",
						ease: "expo.out",
					},
					`-=${0.85 * 1.8}`
				)
				.to(
					".mn_screen .intro_hold .intro_img04",
					1.8,
					{
						scale: 1,
						clipPath: "inset(0%)",
						ease: "expo.out",
					},
					`-=${0.85 * 1.8}`
				)
				.to(
					".mn_screen .intro_hold .intro_img03",
					1.8,
					{
						scale: 1,
						clipPath: "inset(0%)",
						ease: "expo.out",
					},
					`-=${0.85 * 1.8}`
				)

				.to(
					".mn_screen .intro_hold .intro_img06",
					1.8,
					{
						scale: 1,
						clipPath: "inset(0%)",
						ease: "expo.out",
					},
					`-=${0.85 * 1.8}`
				)
				.to(
					".intro_hold_h1 .h2g",
					1.8,
					{
						autoAlpha: 1,
						y: "-50%",
						ease: "expo.out",
					},
					`-=1.9`
				)

				.to(
					".mn_header",
					1,
					{
						autoAlpha: 1,
						pointerEvents: "auto",
						y: 0,
						ease: "expo.out",
					},
					"a+=.7"
				)
				.to(
					".hdn",
					0.01,
					{
						height: "auto",
						onComplete: initPage,
					},
					"a+=.7"
				);
		}
	} else {
		initPage();
	}

	// fadeIns();

	function initPage() {
		console.log("initPage");
		startScroll();
		if (window.innerWidth > 760) {
			console.log("innerWidth > 760");
			expandAbout();
			expandExpertise();
			initMatchMedia();
			animateWords();
		}

		initScrollTo();
	}

	function startScroll() {
		console.log("startScroll");
		function initScroll() {
			console.log("initScroll");
			scroller = document.querySelector(".scroller");

			console.log("got scroller", scroller);

			// 3rd party library setup:
			bodyScrollBar = Scrollbar.init(scroller, {
				damping: 0.07,
				delegateTo: document,
				renderByPixels: true,
				keyDownDisabled: false,
				alwaysShowTracks: true,
			});

			// Tell ScrollTrigger to use these proxy getter/setter methods for the "body" element:
			ScrollTrigger.scrollerProxy(".scroller", {
				scrollTop(value) {
					if (arguments.length) {
						bodyScrollBar.scrollTop = value;
					}
					return bodyScrollBar.scrollTop;
				},
			});

			// when the smooth scroller updates, tell ScrollTrigger to update() too:
			bodyScrollBar.addListener(ScrollTrigger.update);

			ScrollTrigger.defaults({
				scroller: scroller,
			});

			// remove x scrollbar
			bodyScrollBar.track.xAxis.element.remove();
		}

		if (window.innerWidth > 760) {
			initScroll();
			// customCursor();
		} else if (window.innerWidth <= 760) {
			Scrollbar.destroyAll();
		}
	}

	function expandExpertise() {
		console.log("expandExpertise Words");
		const section = document.querySelector(".our-expertise-content-images");
		const el = document.querySelector(".our-expertise-a-bg");

		// Check if both section and element are found before proceeding
		if (!section || !el) {
			console.log("Section or element not found.");
			return;
		}

		// Set initial clip-path circle to 10%
		gsap.set(el, { clipPath: "inset(40%)" });

		// Create a timeline with scroll trigger
		const timeline = gsap.timeline({
			scrollTrigger: {
				pin: true,
				trigger: section,
				start: "top top", // Start animation when the top of the section reaches the bottom of the viewport
				end: "bottom top", // End animation when the bottom of the section reaches the top of the viewport
				scrub: 1,
				// markers: true
			},
		});

		// Animate clip-path circle to 100% when scrolling
		timeline.to(el, {
			clipPath: "inset(0%)",
			duration: 1, // Adjust duration as needed
		}); // Start the text color animation 1 second before the end of the inset animation;
	}

	function expandAbout() {
		console.log("expandCircle Words");
		const trigger = document.getElementById("trigger__aboutBanner");
		const section = document.querySelector(".expandCircle-section");
		const el = document.querySelector(".expandCircle-section__video-wrapper");
		const spanEls = document.querySelectorAll(".expandCircle-section h2 span");
		const textEls = document.querySelectorAll(".expandCircle-section h2");

		// Check if both section and element are found before proceeding
		if (!section || !el) {
			console.log("Section or element not found.");
			return;
		}

		// Set initial clip-path circle to 10%
		gsap.set(el, { clipPath: "inset(40%)" });
		gsap.set(textEls, { color: "#000000" });

		// Create a timeline with scroll trigger
		const timeline = gsap.timeline({
			scrollTrigger: {
				pin: true,
				trigger: section,
				start: "top top", // Start animation when the top of the section reaches the bottom of the viewport
				end: "bottom top", // End animation when the bottom of the section reaches the top of the viewport
				scrub: 1,
			},
		});

		// Animate clip-path circle to 100% when scrolling
		timeline
			.to(el, {
				clipPath: "inset(0%)",
				duration: 1, // Adjust duration as needed
			})
			.to(
				textEls,
				{
					color: "#ffffff", // Change color to white
					duration: 1, // Adjust duration as needed
				},
				"-=1"
			)
			.to(
				spanEls,
				{
					color: "#ffffff", // Change color to white
					duration: 1, // Adjust duration as needed
				},
				"-=1"
			); // Start the text color animation 1 second before the end of the inset animation;
	}

	function animateWords() {
		console.log("Animate Words");
		const section = document.querySelector(".animateWords-section");
		const words = gsap.utils.toArray(".animateWords span");

		// Check if both section and element are found before proceeding
		if (!section || !words) {
			console.error("Section or element not found.");
			return;
		}

		const timeline = gsap.timeline({
			scrollTrigger: {
				// markers: true,
				trigger: section,
				start: "top center", // Start animation when the top of the section reaches the bottom of the viewport
				end: "top 100px", // End animation when the bottom of the section reaches the top of the viewport
				scrub: 1,
			},
		});

		words.forEach((word, index) => {
			timeline.to(word, { opacity: 1 });
		});
	}

	function fadeIns() {
		// fadeInUp
		const fadeInUp = gsap.utils.toArray(".fadeInUp");
		fadeInUp.forEach((box, i) => {
			const anim = gsap.fromTo(box, { autoAlpha: 0, y: -200 }, { duration: 0.5, autoAlpha: 1, y: 0 });
			ScrollTrigger.create({
				trigger: box,
				animation: anim,
				toggleActions: "play none none none",
				once: false,
			});
		});
		// fadeInUpBig
		const fadeInUpBig = gsap.utils.toArray(".fadeInUpBig");
		fadeInUpBig.forEach((box, i) => {
			const anim = gsap.fromTo(box, { autoAlpha: 0, y: -500 }, { duration: 1, autoAlpha: 1, y: 0 });
			ScrollTrigger.create({
				trigger: box,
				animation: anim,
				toggleActions: "play none none none",
				once: false,
			});
		});
		// fadeInDown
		const fadeInDown = gsap.utils.toArray(".fadeInDown");
		fadeInDown.forEach((box, i) => {
			const anim = gsap.fromTo(box, { autoAlpha: 0, y: 200 }, { duration: 0.5, autoAlpha: 1, y: 0 });
			ScrollTrigger.create({
				trigger: box,
				animation: anim,
				toggleActions: "play none none none",
				once: false,
			});
		});
		// fadeInDownBig
		const fadeInDownBig = gsap.utils.toArray(".fadeInDownBig");
		fadeInDownBig.forEach((box, i) => {
			const anim = gsap.fromTo(box, { autoAlpha: 0, y: 500 }, { duration: 1, autoAlpha: 1, y: 0 });
			ScrollTrigger.create({
				trigger: box,
				animation: anim,
				toggleActions: "play none none none",
				once: false,
			});
		});
		// fadeInLeft
		const fadeInLeft = gsap.utils.toArray(".fadeInLeft");
		fadeInLeft.forEach((box, i) => {
			const anim = gsap.fromTo(box, { autoAlpha: 0, x: -200 }, { duration: 0.5, autoAlpha: 1, x: 0 });
			ScrollTrigger.create({
				trigger: box,
				animation: anim,
				toggleActions: "play none none none",
				once: false,
			});
		});
		// fadeInLeftBig
		const fadeInLeftBig = gsap.utils.toArray(".fadeInLeftBig");
		fadeInLeftBig.forEach((box, i) => {
			const anim = gsap.fromTo(box, { autoAlpha: 0, x: -500 }, { duration: 1, autoAlpha: 1, x: 0 });
			ScrollTrigger.create({
				trigger: box,
				animation: anim,
				toggleActions: "play none none none",
				once: false,
			});
		});

		// fadeInRight
		const fadeInRight = gsap.utils.toArray(".fadeInRight");
		fadeInRight.forEach((box, i) => {
			const anim = gsap.fromTo(box, { autoAlpha: 0, x: 200 }, { duration: 0.5, autoAlpha: 1, x: 0 });
			ScrollTrigger.create({
				trigger: box,
				animation: anim,
				toggleActions: "play none none none",
				once: false,
			});
		});

		// fadeInRightBig
		const fadeInRightBig = gsap.utils.toArray(".fadeInRightBig");
		fadeInRightBig.forEach((box, i) => {
			const anim = gsap.fromTo(box, { autoAlpha: 0, x: 500 }, { duration: 1, autoAlpha: 1, x: 0 });
			ScrollTrigger.create({
				trigger: box,
				animation: anim,
				toggleActions: "play none none none",
				once: false,
			});
		});
	}

	function initPageMb() {
		$("body").removeClass("hdn");
		// startScroll();
		// initMatchMedia();
		// initOther();
	}

	function initScrollTo() {
		// find all links and animate to the right position
		gsap.utils.toArray(".footer-right-links a").forEach((link) => {
			const target = link.getAttribute("href");
			link.addEventListener("click", (e) => {
				e.preventDefault();
				//console.log(select(target));
				bodyScrollBar.scrollIntoView(select(target), {
					damping: 0.07,
					offsetTop: 0,
				});
			});
		});
	}

	function initMatchMedia() {
		console.log("initMatchMedia");

		ScrollTrigger.matchMedia({
			"(min-width: 761px) and (max-width: 6500px)": function () {
				const h = window.innerHeight;
				const w = window.innerWidth;

				if ($(".mn_screen").length) {
					let tlFixFirstScreen = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreen",
							trigger: ".mn_screen",
							pin: true,
							pinSpacing: true,
							scrub: 1,
							start: `top top`,
							end: `+=${h * 1.2}`,
							//immediateRender: true,
						},
					});

					let tlFixFirstScreenMove01 = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove01",
							trigger: "#mns_id_set1",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=${h}`,
							//immediateRender: true,
						},
					});

					tlFixFirstScreenMove01
						.add("f")
						.to(
							".intro_img01",
							1,
							{
								xPercent: -250,
								yPercent: 220,
								ease: "none",
							},
							"f"
						)
						.to(
							".ms_txt",
							1,
							{
								autoAlpha: 0,
								ease: "none",
							},
							"f"
						)
						.to(
							".mdl_cnt",
							0.1,
							{
								autoAlpha: 1,
								ease: "none",
							},
							"f"
						);

					let tlFixFirstScreenMove01Ops = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove01Ops",
							trigger: "#mns_id_set1_ops",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=50`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove01Ops.to(".intro_img01", {
						autoAlpha: 0,
						ease: "none",
					});

					let tlFixFirstScreenMove02 = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove02",
							trigger: "#mns_id_set2",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=${h}`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove02.to(".intro_img02", {
						xPercent: -180,
						yPercent: -250,
						ease: "none",
					});

					let tlFixFirstScreenMove02Ops = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove02Ops",
							trigger: "#mns_id_set2_ops",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=50`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove02Ops.to(".intro_img02", {
						autoAlpha: 0.3,
						ease: "none",
					});

					let tlFixFirstScreenMove06 = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove06",
							trigger: "#mns_id_set6",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=${h}`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove06.to(".intro_img06", {
						xPercent: 195,
						yPercent: 255,
						ease: "none",
					});

					let tlFixFirstScreenMove06Ops = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove06Ops",
							trigger: "#mns_id_set6_ops",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=50`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove06Ops.to(".intro_img06", {
						autoAlpha: 0.3,
						ease: "none",
					});

					let tlFixFirstScreenMove05 = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove05",
							trigger: "#mns_id_set5",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=${h}`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove05.to(".intro_img05", {
						xPercent: 165,
						yPercent: -150,
						ease: "none",
					});

					let tlFixFirstScreenMove05Ops = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove05Ops",
							trigger: "#mns_id_set5_ops",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=50`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove05Ops.to(".intro_img05", {
						autoAlpha: 0,
						ease: "none",
					});

					let tlFixFirstScreenMove04 = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove04",
							trigger: "#mns_id_set4",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=${h}`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove04.to(".intro_img04", {
						xPercent: -250,
						yPercent: -320,
						ease: "none",
					});

					let tlFixFirstScreenMove04Ops = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove04Ops",
							trigger: "#mns_id_set4_ops",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=50`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove04Ops.to(".intro_img04", {
						autoAlpha: 0.3,
						ease: "none",
					});

					let tlFixFirstScreenMove07 = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove07",
							trigger: "#mns_id_set7",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=${h}`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove07.to(".intro_img07", {
						xPercent: 235,
						yPercent: -360,
						ease: "none",
					});

					let tlFixFirstScreenMove07Ops = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove07Ops",
							trigger: "#mns_id_set7_ops",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=50`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove07Ops.to(".intro_img07", {
						autoAlpha: 0.3,
						ease: "none",
					});

					let tlFixFirstScreenMove03 = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove03",
							trigger: "#mns_id_set3",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=${h}`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove03.to(".intro_img03", {
						xPercent: -220,
						yPercent: -280,
						ease: "none",
					});

					let tlFixFirstScreenMove03Ops = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove03Ops",
							trigger: "#mns_id_set3_ops",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=50`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove03Ops.to(".intro_img03", {
						autoAlpha: 0.3,
						ease: "none",
					});

					// let tlFixFirstScreenOpc = gsap.timeline({
					// 	scrollTrigger: {
					// 		id: "tlFixFirstScreenOpc",
					// 		trigger: '#mns_id_set2_ops',
					// 		pin: false,
					// 		pinSpacing: false,
					// 		scrub: 1,
					// 		start: `top top`,
					// 		end: `+=50`,
					// 		//immediateRender: true,
					// 	}
					// });
					// tlFixFirstScreenOpc
					// 	.add('fo')
					// 	.to('.intro_img01', {autoAlpha: .3,  ease: 'none'}, 'fo')
					// 	.to('.intro_img02', {autoAlpha: .3, ease: 'none'}, 'fo')
					// 	.to('.intro_img03', {autoAlpha: .3, ease: 'none'}, 'fo')
					// 	.to('.intro_img04', {autoAlpha: .3, ease: 'none'}, 'fo')
					// 	.to('.intro_img05', {autoAlpha: .3, ease: 'none'}, 'fo')
					// 	.to('.intro_img06', {autoAlpha: .3, ease: 'none'}, 'fo')
					// 	.to('.intro_img07', {autoAlpha: .3, ease: 'none'}, 'fo');
				}
			},
		});
	}
// });
});
